//default spacing helper function
@function spacing($size) {
  $result: 1;
  $result: $size * 0.25;
  @return #{$result}rem;
}

@for $i from 0 through 20 {
  //margin utility

  .u-mar_#{$i} {
    margin: spacing($i);
  }

  .u-mar-v_#{$i} {
    margin-top: spacing($i);
    margin-bottom: spacing($i);
  }

  .u-mar-h_#{$i} {
    margin-left: spacing($i);
    margin-right: spacing($i);
  }

  .u-mar-t_#{$i} {
    margin-top: spacing($i);
  }

  .u-mar-b_#{$i} {
    margin-bottom: spacing($i);
  }

  .u-mar-r_#{$i} {
    margin-right: spacing($i);
  }

  .u-mar-l_#{$i} {
    margin-left: spacing($i);
  }

  // Padding utility

  .u-pad_#{$i} {
    padding: spacing($i);
  }

  .u-pad-v_#{$i} {
    padding-top: spacing($i);
    padding-bottom: spacing($i);
  }

  .u-pad-h_#{$i} {
    padding-left: spacing($i);
    padding-right: spacing($i);
  }

  .u-pad-t_#{$i} {
    padding-top: spacing($i);
  }

  .u-pad-b_#{$i} {
    padding-bottom: spacing($i);
  }

  .u-pad-r_#{$i} {
    padding-right: spacing($i);
  }

  .u-pad-l_#{$i} {
    padding-left: spacing($i);
  }
}

@for $i from 0 through 20 {
  .br-#{$i} {
    border-radius: #{$i}px;
  }

  .br-bl-#{$i} {
    border-bottom-left-radius: #{$i}px;
  }

  .br-br-#{$i} {
    border-bottom-right-radius: #{$i}px;
  }
}
