.NoAddressMessage {
  background: #fff;
  color: #777;
  margin: 0;
  border-radius: 0px 0px 16px 16px;
  animation: delay-enter 1s forwards ease;
  text-align: center;
}

.location-search__container {
  position: relative;
}

.location-search-clear {
  position: absolute;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
}

.location-search-icon {
  position: absolute;
  top: 50%;
  left: 0.75rem;
  transform: translateY(-50%);
}

@keyframes delay-enter {
  0% {
    opacity: 0;
    height: 0;
    padding: 0;
  }
  80% {
    opacity: 0;
    height: 0;
    padding: 0;
  }
  100% {
    opacity: 1;
    height: auto;
    padding: 10px;
  }
}

.checkerPageContentContainer {
  text-align: center;
}

.restaurantInfoContainer {
  display: flex;
  max-width: 92%;
  margin: auto;
  align-items: center;
  justify-content: center;
}

.restaurantLogo {
  position: relative;
  width: 120px;
  vertical-align: middle;
  display: inline-block;
}

.restaurauntTextContainer {
  display: inline-block;
  vertical-align: middle;
  text-align: left;
  margin-left: 12px;
  margin-bottom: 16px;
}

.restaurantName {
  font-weight: 900;
  font-size: 24px;
  line-height: 1.1;
  margin-bottom: 4px;
}

.restaurantAddress {
  font-weight: 300;
  font-size: 16px;
  line-height: 1.2;
}

@media only screen and (max-width: 350px) {
  .restaurantLogo {
    width: 96px;
  }
  .restaurantName {
    font-size: 20px;
  }
  .restaurantAddress {
    font-size: 14px;
  }
}

.hours {
  font-size: 18px;
  font-weight: 700;
  margin-top: 16px;
}

.deliveryButtonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  max-width: 360px;
  border: 1px solid #eee;
  border-radius: 6px;
  height: 45px;
  margin: auto;
  overflow: hidden;
  margin-top: 2vh;
}

.deliveryButton {
  display: inline-block;
  position: relative;
  width: 50%;
  height: 100%;
  border: 0;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
}

.buttonBlue {
  background: #6badf4;
  color: #fff;
  font-weight: 900;
}

.buttonWhite {
  background: #fff;
  color: #999;
  font-weight: 500;
}

.addressWrapper {
  position: relative;
  margin-top: 2.5vh;
  display: inline-block;
  width: 100%;
  max-width: 440px;
  animation: drop-in ease 500ms forwards;
  z-index: 2;
}

#spin {
  animation-name: spin;
  animation-duration: 1500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.autocompleteWrapper {
  display: block;
}

.autocompleteContainer {
  text-align: center;
  border: 0px;
  position: relative;
  width: 100%;
}

.curLoc {
  background: #ddd;
  position: absolute;
  top: 4px;
  right: 4px;
  height: calc(100% - 8px);
  transition: all 0.5s ease;
  padding: 0;
  z-index: 990;
  border-radius: 5px;
  box-shadow: 0;
  border: 0;
  transition: all 0.1s ease;
}

.curLoc:hover {
  transform: scale(1.02);
  box-shadow: 1px 5px 20px -5px #ccc;
}

.curLocImg {
  height: calc(100% - 20px);
  padding: 10px;
  margin-right: 0;
  vertical-align: 0;
  cursor: pointer;
}

.location-search-input {
  text-align: center;
  font-size: 16px;
  color: #555;
  border-radius: 3px;
  font-weight: 300;
  width: 100%;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: 0px;
  font-weight: 400;
  box-sizing: border-box;
  overflow: hidden;
}

.location-search-input--dark {
  border: 1px solid #20205d;
}

.location-search-input::placeholder {
  color: #999;
  font-weight: 400;
}

.autocompleteContainer,
.autocomplete-dropdown-container {
  font-size: 1rem;
  background-color: white;
  z-index: 10;
  color: #555;
  font-weight: 400;
}
.autocomplete-dropdown-container {
  position: absolute;
  box-shadow: 0px 6px 9px 0px rgba(0, 0, 0, 0.12),
    0px 0px 15px 0px rgba(0, 0, 0, 0.06);
  top: 55px;
  border: 1px solid #20205d;
  width: 100%;
  border-radius: 3px;
  overflow: hidden;
}

.autocomplete-dropdown-container:empty {
  display: none;
}

.loadingText,
.bg,
.loader {
  z-index: 999999;
  position: fixed;
}

.errorContainer {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.suggestion-item {
  position: relative;
  background-color: #fff;
  cursor: pointer;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
}

.suggestion-item--active {
  background-color: #f4f4fc;
  cursor: pointer;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 700;
  text-align: left;
}

.location-search-input--left {
  text-align: left;
}

.location-search-input--center {
  text-align: center;
}
