$primary-blue: #20205d;
$primary-pink: #fb0277;

$bp-s: 900px;
$bp-m: 900px;
$bp-ml: 1201px;
$bp-l: 1441px;

//Header constants
$header-mobile: 54px;

$story-width: 350px;
$feed-width: calc(100% - 50px - #{$story-width});

$loading-grey: #e2e2e8;

@mixin mobile {
  @media screen and (max-width: #{$bp-s}) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: 901px) {
    @content;
  }
}
