@import "~fileConstants";

.rest-preview-container {
  text-decoration: none;
  color: black;
  display: flex;
  justify-content: space-between;
}

.rest-preview-container {
  border-bottom: 1px solid #dfdede;
}

.rest-preview-container--loading {
  background: white;
  // border: none;
}

.rest-preview__image-placeholder {
  min-height: 60px;
  border-radius: 6px;
  min-width: 60px;
  @include mobile {
    min-width: 48px;
    min-height: 48px;
  }
}

.rest-preview__image-placeholder--loading {
  background: $loading-grey;
}

.rest-preview__image--bg {
  background: #d8d8d8;
}

.rest-preview__tag {
  background: #c4c4c4;
  padding: 0.1rem 0.5rem;
  border-radius: 12px;
}
.rest-preview__tag:not(:first-child) {
  margin: 0 0.5rem;
}
