.modal-root__container {
  z-index: 10;
  // bottom: 0;
  // top: 0;
  position: fixed;
  height: 100%;
  width: 100vw;
}

#modal-root {
  // z-index: 1001;
}

.modal-inner {
  // position: absolute;
  top: 0;
  left: 0;
  overflow-y: auto;
  height: 100%;
}

.modal-inner--initial-height {
  height: initial;
  // width: 100%;
}
