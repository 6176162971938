@import "~fileConstants";

.feed-header__container {
  @include desktop {
    position: fixed;
    max-width: $story-width;
    display: flex;
    align-items: center;
    flex-direction: column;
    height: calc(100% - 123px);
    overflow-y: auto;
  }
}

.feed-container {
  @include desktop {
    display: flex;
  }
}
.direct-banner-margin {
  height: calc(100% - 136px);
  margin-top: 136px;
}
.rest-feed__container {
  background: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @include desktop {
    max-width: $feed-width;
    margin-left: auto;
  }
}
.rest-feed__top-rated {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  // display: grid;
  // grid-row-gap: 1.5rem;
  // grid-column-gap: 1.5rem;
  // grid-template-columns: minmax(150px, 1fr) minmax(150px, 1fr);
}

.change-address__container {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.change-address__recent-clear {
  border-radius: 20px;
  padding: 0.25rem 0.5rem;
  border: none;
  transition: background-color 100ms;
  background: transparent;

  &:hover {
    background: #f2f2fd;
  }
}

.discover-header--fixed {
  background: white;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);

  transition-duration: 300ms;
  transition-property: all;
}

.change-address__header-container {
  align-items: center;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.search-icon {
  position: absolute;
  left: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

/* clears the 'X' from Internet Explorer */
input[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

.search-input {
  -webkit-appearance: none;
  position: relative;
  border: 1px solid $primary-blue;
  border-radius: 35px;
  font-weight: 500;
  font-size: 16px;
}
.search-input::placeholder {
  color: #a5a5a5;
}

.direct-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 50%;
  padding: 0.75rem;
}

.discover-scroll-top {
  border-radius: 50%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background: $primary-blue;
  color: white;
}

.direct-banner-appear {
  opacity: 0;
  transform: translateY(-85px);
}

.direct-banner-appear.direct-banner-appear-active {
  opacity: 1;
  transform: translateY(0px);
  transition: opacity 0.5s ease-in,
    transform 500ms cubic-bezier(0.2, 0.05, 0.06, 0.98);
}

.direct-banner-leave {
  opacity: 1;
  transform: translateY(0px);
}

.direct-banner-leave.direct-banner-leave-active {
  opacity: 0;
  transition: opacity 0.5s ease-in,
    transform 500ms cubic-bezier(0.2, 0.05, 0.06, 0.98);
  transform: translateY(-85px);
}

.rests-appear {
  opacity: 0;
  transform: translateY(-1rem);
}

.rests-appear.rests-appear-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 500ms cubic-bezier(0.2, 0.05, 0.06, 0.98),
    transform 500ms cubic-bezier(0.2, 0.05, 0.06, 0.98);
}

.scroll-top-appear {
  transform: scale3d(0.1, 0.1, 0.1);
}

.scroll-top-appear.scroll-top-appear-active {
  transform: scale3d(1, 1, 1);
  transition: transform 300ms cubic-bezier(0.19, 1, 0.22, 1);
}

.discover-header-appear {
  transform: translateY(-70px);
}

.discover-header-appear.discover-header-appear-active {
  transform: translateY(0px);
  transition: transform 300ms cubic-bezier(0.215, 0.61, 0.355, 1);
}

.discover-header-leave {
  transform: translateY(0px);
}

.discover-header-leave.discover-header-leave-active {
  transition: transform 300ms cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translateY(-70px);
}

.discover__email-list {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
}

.email-list__close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5rem;
  margin: 0.5rem;
}

.discover-modal {
  max-width: 700px;
  margin: 0 auto;
  @include mobile {
    height: 100%;
  }
}
