@import "~fileConstants/index";

.home-container {
  height: calc(100% - #{$header-mobile});
  @include desktop {
    background-image: url("../../assets/smile-bg.svg");
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
}

.home__content-container {
  display: flex;
  flex-direction: column;

  align-items: center;

  justify-content: center;
}

.home__splash {
  width: 100%;
  display: flex;
  justify-content: center;
}

.home__header {
  color: white;
  line-height: 1.2;
}

.home__address {
  text-align: center;
  font-size: 16px;
}

.home__actions-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0 1rem;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.home__actions-divide {
  color: white;
  position: relative;
}

.home__actions-divide::before {
  content: " ";
  width: 10px;
  position: absolute;
  right: 100%;
  top: 50%;
  transform: translate(-100%, -50%);
  height: 2px;
  background-color: white;
}

.home__actions-divide::after {
  content: " ";
  width: 10px;
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translate(100%, -50%);
  height: 2px;
  background-color: white;
}

.home__actions--dark::before,
.home__actions--dark::after {
  background-color: $primary-blue;
}

.home__actions--dark {
  color: $primary-blue;
}

.home__actions-current {
  position: relative;
  background-color: $primary-pink;
  border: none;
  border-radius: 3px;
  font-weight: 500;
  font-size: 1rem;
  color: white;
  width: 100%;

  @include desktop {
    background-color: transparent;
    color: $primary-pink;
    border-bottom: 1px solid $primary-pink;
    width: initial;
    border-radius: 0px;
    padding: 0;
    // margin: 1rem;
  }
}

.home__actions-current:focus {
  @include desktop {
    outline: none;
    border-bottom: 1px solid darken($color: white, $amount: 20%);
  }
}

.home__actions-current:active {
  @include desktop {
    outline: none;
    border-bottom: 2px solid white;
  }
}

.home__actions-curent--dark {
  @include desktop {
    color: $primary-blue;
    border-bottom-color: $primary-blue;
    background-color: transparent;
    & > .home__actions-current-icon > path {
      fill: $primary-blue;
    }
  }
}

.home__actions-curent--dark:active,
.home__actions-curent--dark:focus {
  @include desktop {
    border-bottom: 2px solid darken($color: $primary-blue, $amount: 30%);
  }
}

.home__actions-current--loading {
  pointer-events: none;
  cursor: not-allowed;
}

.home__actions-current-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0.5rem;

  @include desktop {
    left: -1.5rem;

    & > path {
      fill: $primary-pink;
    }
  }
}

.home-two-foood {
  padding: inherit;
}

.home-two--foodmoji {
  position: absolute;
  left: 15px;
  top: 50px;
}

.home-tag {
  max-width: 10rem;
}

.home-sign-up {
  border: 1px solid #999999;
  font-size: 1rem;
}

.home-sign-up::placeholder {
  font-weight: 500;
  font-size: 0.65rem;
  color: #999999;
}

.home-input--error {
  position: relative;
  &::before {
    content: "This field is required ";
    position: absolute;

    top: -26px;
    left: 0;
    padding: 0.25rem;
    font-size: 0.75rem;
    color: white;
  }
  border: 3px solid #ff3333;
}

.home-input--error--red {
  @extend .home-input--error;
  &::before {
    color: #ff3333;
  }
}

.home-smile {
  animation: bounce 1.5s cubic-bezier(0.22, 1, 0.36, 1) 1s infinite;
  border-radius: 50%;
  padding: 1rem 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  transition: background-color 150ms linear;
  &:hover {
    // background: rgba(255, 194, 223, 0.1);
    background: rgb(72, 72, 139);
  }
}

@keyframes bounce {
  0%,
  25%,
  50%,
  75%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-7px);
  }

  60% {
    transform: translateY(-4px);
  }
}

///////////////// the below is for the icon smile symbols on the home page ///////////////////////////

.home-header-buttons {
  margin-bottom: 15px;
}

#daddy-1 {
  .smile-circle {
    display: none;
  }
  .inner-circle {
    display: none;
  }
}

#daddy-2 {
  .smile-circle {
    display: none;
  }
  .inner-circle {
    display: none;
  }
}

.daddy-button {
  margin-right: 0.5em;
  position: relative;
  width: 10px;
  padding-bottom: 5px;
  height: auto;
  background: #20205e;
  height: 9px;
  display: inline-block;
}

.no-style-button {
  margin: 0em;
  height: 10px;
  width: 10px;
  position: absolute;
  left: -4px;
  background: none;
  border: none;
  outline: none;
}

.smile-circle {
  position: absolute;
  bottom: 0px;
  border: 2px #ed0f77 solid;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  display: block;
  z-index: 1;
}

.smile-blocker {
  background: #20205e;
  width: 15px;
  height: 10px;
  position: absolute;
  bottom: 4px;
  z-index: 2;
}

.inner-circle {
  border: 0px #ed0f77 solid;
  position: absolute;
  width: 8px;
  height: 8px;
  left: 3px;
  background: #ed0f77;
  border-radius: 50%;
  margin-right: auto;
  margin-left: auto;
  z-index: 9;
}

.inner-circle-unselected {
  border: 2px #48488b solid;
  position: absolute;
  width: 4px;
  height: 4px;
  left: 3px;
  border-radius: 50%;
  margin-right: auto;
  margin-left: auto;
  z-index: 7;
}

///////////////// the above is for the icon smile symbols on the home page ///////////////////////////

.home-two-container {
  background-image: url("/background.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.home-foood-desktop {
  min-width: 256px;
}

.home-four-left {
  // max-width: 400px;
  // margin-left: auto;
}

.home-four-right {
  // margin-right: auto;
}

.home-four-container {
  max-width: 600px;
}

.home-desktop-text {
  opacity: 0;
}

.home-desktop-text-appear {
  opacity: 0;
  transform: translateY(-30px);
}

.home-desktop-text-appear.home-desktop-text-appear-active {
  opacity: 1;
  transform: translateY(0px);
  transition: opacity 100ms ease-in,
    transform 500ms cubic-bezier(0.2, 0.05, 0.06, 0.98);
}

.home-desktop-text-leave {
  opacity: 1;
  transform: translateY(0px);
}

.home-desktop-text-leave.home-desktop-text-leave-active {
  opacity: 0;
  transition: opacity 100ms ease-in,
    transform 200ms cubic-bezier(0.2, 0.05, 0.06, 0.98);
  transform: translateY(30px);
}

.animate-in {
  opacity: 0;

  animation: fade-in 750ms cubic-bezier(0.16, 1, 0.3, 1);
  animation-fill-mode: forwards;
}

@for $i from 1 through 3 {
  .animate-in:nth-child(#{$i}) {
    animation-delay: (#{$i * 0.25s});
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(40px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

.home-footer {
  flex-wrap: wrap;
  @include mobile {
    flex-direction: column;
    align-items: flex-start;
  }
}

.home-footer__two {
  @include mobile {
    margin-top: 3rem;
    align-items: flex-start;
  }
}

.manifesto-container {
  background-image: url("../../assets/smile-faded-pink.svg");
  background-repeat: no-repeat;
  background-position: center;
}
