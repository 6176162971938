@import "~fileConstants";

.nav-container {
  min-height: $header-mobile;
  color: white;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1000;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  background-color: $primary-blue;
}

.nav-container::before {
  content: " ";
  height: 50px;
  width: 100%;
  background: $primary-blue;
  position: fixed;
  top: -50px;
}

.nav__content-container {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
}

.nav__address-container {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: 0;
  transform: translate(-50%, -50%);
}
