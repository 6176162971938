@import "~fileConstants";

.rest-back {
  transition: background-color 200ms;
  border-radius: 20px;
  padding: 0.25rem 0.75rem;
  margin-left: -0.75rem;
}

.rest-back:focus {
  outline: none;
  border: 1px solid #7171bf;
}

.rest-back > svg {
  transition: transform 150ms ease-out;
}

.rest-back:hover {
  background-color: lighten($color: $primary-blue, $amount: 5%);
  & > svg {
    transform: translateX(-2px);
  }
}

.rest-page__content-container {
  background: $primary-blue;
  position: relative;
  height: 100%;
}

.rest-page__container {
  @include desktop {
    display: flex;
    overflow-x: hidden;
    background-image: url("../../assets/smile-faded-pink.svg");
    background-repeat: no-repeat;
    align-items: center;
    background-position: center;
    justify-content: space-between;
  }
}

.rest-info__phone {
  text-decoration: none;
}

.address-banner-container {
  max-width: 640px;
  margin: 0 auto;
  background-color: white;

  @include desktop {
    background: transparent;
  }
}

.rest-page__no-image {
  background: #d8d8d8;
}

.rest-place-order {
  position: fixed;
  bottom: 0;
  z-index: 10;
  box-sizing: border-box;
  width: 100%;
}

.order-button-appear {
  opacity: 0;
  transform: translateY(1rem);
}

.order-button-appear.order-button-appear-active {
  opacity: 1;
  transform: translateY(0);
  transition: transform 750ms cubic-bezier(0.2, 0.05, 0.06, 0.98),
    opacity 750ms cubic-bezier(0.2, 0.05, 0.06, 0.98);
}

.rest-order-button {
  @include mobile {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
}

.rest-page--desktop {
  width: 400px;
  // height: 100%;
  padding: 1.5rem;
}

.rest-page__image {
  background-color: white;
  box-shadow: 3px 3px 0px $primary-pink;
}

.rest-link {
  color: white;
  text-decoration: underline;
}

.rest-page-screenshot {
  // width: 600px;
  // height: calc(100% - 300px);
  width: 455px;
  height: 285px;
  // position: fixed;
  object-fit: cover;
  object-position: top;
  font-family: "object-fit: cover; object-position: top;";
  // position: absolute;
  // left: 72px;
  // top: 24px;

  margin-top: -64px;

  @include mobile {
    width: 100%;
    margin-top: 0;
    position: initial;
    height: 200px;
  }
}

.rest-page__screenshot-container {
  // overflow: hidden;
  width: 600px;
  perspective: 50px;
  @include mobile {
    width: 100%;
    height: 200px;
  }
}
.rest-page__screenshot-container--no-image {
  background: #d8d8d8;
}

.rest-page__logo {
  object-fit: contain;
  font-family: "object-fit: contain;";
  width: 90px;
  height: 90px;

  @include desktop {
    width: 117px;
    height: 117px;
  }
}

.rest-page__laptop {
  background-image: url("/laptop.png");
  background-position: top;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  background-repeat: no-repeat;
  width: 100%;
  height: 400px;
  transition: transform 200ms;
}

.restaurant-rec__container {
  padding: 0.5rem;
  box-shadow: 0px 0.882927px 4.41463px rgba(32, 32, 93, 0.25);
  display: flex;
  flex-grow: 1;
  // flex-basis: calc(50% - 40px);
  align-items: flex-start;
  flex-direction: row;
}

.rest-recs {
  display: flex;
  flex-wrap: wrap;
  max-width: 600px;

  @include mobile {
    flex-direction: column;
  }
}

.rest-recs > a {
  text-decoration: none;
  display: flex;
  background: white;
  flex-grow: 1;

  flex-basis: calc(50% - 40px);
  justify-content: center;
  flex-direction: column;
  @include mobile {
    flex-basis: 100%;
  }
}

.rest-recs > a:hover {
  text-decoration: underline;
  text-decoration-color: $primary-pink;
  text-decoration-thickness: 3px;
}
