@import "~fileConstants";

.button-wrapper {
  background: transparent;
  cursor: pointer;
  border: none;
  padding: 0;
}

.button-wrapper--filled {
  background: inherit;
}

.button-wrapper--#20205d {
  background-color: $primary-blue;
}

.button-wrapper--#fb0277 {
  background-color: $primary-pink;
}

.button-wrapper--blue {
  background: $primary-blue;

  &:hover {
    background: darken($primary-blue, 7.5%);
  }
}

.button-wrapper--pink {
  background-color: $primary-pink;
  &:hover {
    background: darken($primary-pink, 7.5%);
  }
}

.button-wrapper--border-pink {
  color: $primary-pink;
  transition: background-color 100ms linear, color 100ms linear;
  border: 1px solid $primary-pink;
  &:hover {
    background-color: $primary-pink;
    color: white;
  }
}
