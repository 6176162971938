.popover__wrapper {
  & > *:first-child:hover {
  }
  z-index: 10;
}

.popover__container {
  position: absolute;
  z-index: 10;
  background: white;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12);
  padding: 8px;
  border-radius: 8px;
}
