@import "~fileConstants";

.a-text {
  font-family: "Montserrat", sans-serif;
  line-height: 1.42857;
}

.a-text--xs {
  font-size: 0.65rem;
}

.a-text--s {
  font-size: 0.75rem;
}

.a-text--m {
  font-size: 1rem;
}
.a-text--l {
  font-size: 1.25rem;
}

.a-text--lxl {
  font-size: 2rem;
}

.a-text--xl {
  font-size: 2.5rem;
  line-height: 1.3;
}

.a-text--light {
  font-weight: 300;
}

.a-text--regular {
  font-weight: 400;
}

.a-text--medium {
  font-weight: 500;
}

.a-text--semibold {
  font-weight: 600;
}

.a-text--bold {
  font-weight: 700;
}

.a-text--extrabold {
  font-weight: 800;
}

.a-text--black {
  font-weight: 900;
}

.a-text--right {
  text-align: right;
}

.a-text--center {
  text-align: center;
}
.a-text--left {
  text-align: left;
}

.a-text--blue {
  color: $primary-blue;
}

.a-text--pink {
  color: $primary-pink;
}

.a-text--white {
  color: white;
}
