@import "~fileConstants";

.top-rated-container {
  display: flex;
  box-shadow: 0px 1px 5px rgba(32, 32, 93, 0.25);
  text-decoration: none;
  color: #000;
  align-items: flex-start;
  flex-direction: row;
  padding: 1rem;
  margin: 0.5rem 0;
  padding-right: 1.5rem;
}

.top-rated-container--border {
  border-left: 10px solid $primary-pink;
}

.top-rated-container--loading {
  background: white;
  border-left: 10px solid #e3e3e8;
}

.top-rated-address--loading {
  width: 75px;
  height: 8px;
  background: $loading-grey;
}

.top-rated__image-place--loading {
  background: $loading-grey;
}

.top-rated-name--loading {
  width: 125px;
  height: 15px;
  background: $loading-grey;
}

.loading-shine {
  position: relative;
  will-change: transform;
  animation: placeHolderShimmer 1s linear infinite forwards;
  -webkit-backface-visibility: hidden;
  background: $loading-grey;
  background: linear-gradient(90deg, #eee 8%, #ddd 18%, #eee 33%);
  background-size: 800px 104px;
  position: relative;
}

.loading-shine--white {
  background-color: white;
}

@keyframes placeHolderShimmer {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    background-position: -468px 0;
  }
  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    background-position: 468px 0;
  }
}
@keyframes loading {
  100% {
    transform: translateX(100%);
  }
}

.top-rated__image-place {
  min-height: 75px;
  min-width: 75px;
  height: 75px;
  width: 75px;
  border-radius: 6px;
  overflow: hidden;
  @include mobile {
    min-height: 63px;
    min-width: 63px;
    width: 63px;
    height: 63px;
  }

  @include mobile {
    // height: 70px;
  }
}

.top-rated-rest__image {
  object-fit: contain;
  font-family: "object-fit: contain;";
  width: 63px;
  height: 63px;

  @include desktop {
    width: 75px;
    height: 75px;
  }
}
