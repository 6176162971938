.fixed-header {
  position: fixed;
  z-index: 1;
}

.fixed-header--top {
  top: 0;
}

.fixed-header--bottom {
  bottom: 0;
}
