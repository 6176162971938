@import "~fileConstants";

html,
body {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  box-sizing: border-box;
  // background-color: $primary-blue;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input,
textarea {
  box-sizing: border-box;
}

html,
button,
input,
textarea {
  font-family: "Montserrat", sans-serif;
}
#root {
  background-color: white;
  position: absolute;
  height: 100%;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button {
  cursor: pointer;
}

.app-container {
  height: calc(100% - #{$header-mobile});
  padding-top: $header-mobile;
  // padding: 32px;
}

@media only screen and (min-width: $bp-l) {
  html {
    font-size: 20px;
  }
}

@media only screen and (min-width: 1201px) {
  .fluid-content-l {
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (min-width: $bp-m) {
  .fluid-content,
  .fluid-content-m {
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (min-width: $bp-s) {
  .fluid-content-s {
    margin-left: auto;
    margin-right: auto;
  }
}

// Breakpoint 600 px
@media only screen and (max-width: $bp-s) {
  .app-container {
    // padding: 16px;
  }
}

/**** Helper classes ****/

.u-mar_auto {
  margin: auto;
}

.u-mar-t_auto {
  margin-top: auto;
}

.u-mar-b_auto {
  margin-bottom: auto;
}

.u-mar-l_auto {
  margin-left: auto;
}

.u-mar-r_auto {
  margin-right: auto;
}

.u-mar-v_auto {
  margin: auto 0;
}

.u-mar-h_auto {
  margin: 0 auto;
}

.nav-margin {
  margin-top: $header-mobile;
}

.no-wrap {
  white-space: nowrap;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-align-end {
  align-items: flex-end;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-apart {
  justify-content: space-between;
}

.flex-justify-center {
  justify-content: center;
}

.flex-align-start {
  align-items: flex-start;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-grow {
  flex-grow: 1;
}

.flex-evenly {
  justify-content: space-evenly;
}

.flex-align-center {
  align-items: center;
}

.fluid-content-l {
  max-width: 1200px;
}

.fluid-content {
  max-width: 900px;
}

.fluid-content-m {
  max-width: 1000px;
}

.fluid-content-s {
  max-width: 600px;
}

.scroll-y {
  overflow-y: auto;
}

.fill-full {
  width: 100%;
  height: 100%;
}

.fill-width {
  width: 100%;
  box-sizing: border-box;
}

.fill-height {
  height: 100%;
}

.fill-height-screen {
  height: 100vh;
}

.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.bg-pink {
  background-color: $primary-pink;
}

.bg-blue {
  background-color: $primary-blue;
}

.bg-white {
  background-color: white;
}

.p-relative {
  position: relative;
}

.z-1 {
  z-index: 1;
}

.z-10 {
  z-index: 10;
}

.border-bottom {
  border-bottom: 1px solid #dfdede;
}

.box {
  box-sizing: border-box;
}

.link-none {
  text-decoration: none;
}

.link-white {
  text-decoration: none;
  border-bottom: 1px solid white;
}

.min-50 {
  min-width: 50%;
}

.max-50 {
  max-width: 50%;
}

.max-60 {
  max-width: 60%;
}

.max-70 {
  max-width: 70%;
}

.max-80 {
  max-width: 80%;
}
.max-90 {
  max-width: 90%;
}

.top-right {
  position: absolute;
  top: 0;
  right: 0;
}

.bottom {
  position: absolute;
  bottom: 0;
}

.hover-blue:hover {
  background-color: darken($color: $primary-blue, $amount: 10%);
}

.hover-pink:hover {
  background-color: darken($color: $primary-pink, $amount: 10%);
}
.br-circle {
  border-radius: 50%;
}

.border-none {
  border: none;
}

.o-hidden {
  overflow: hidden;
}

.fixed-top {
  position: fixed;
  top: 0;
}

.flex-b-50 {
  flex-basis: 50%;
}

.nav-height {
  height: calc(100% - #{$header-mobile});
  margin-top: $header-mobile;
  overflow-y: auto;
}

.fixed-under-nav {
  position: fixed;
  top: $header-mobile;
}

.initial-height {
  height: initial;
}
