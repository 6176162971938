@import "~fileConstants";

.stories-container {
  display: flex;
  overflow-x: auto;

  @include desktop {
    overflow: initial;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}

.story-container {
  border: none;
  min-width: 75px;
  display: flex;
  -webkit-overflow-scrolling: touch;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: transparent;

  @include desktop {
    flex: 0 0 25%;
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
  }
}

.story-container:focus {
  outline: none;
}

.story-container:active {
}

.story-container:focus > .story-image {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  transition: transform 50ms ease-in;
  transform: translateY(-2px);
}

.story-container:first-child {
  padding-left: 0.25rem;
  margin-left: 0.5rem;
}

.story-container:last-child {
  padding-right: 0.25rem;
  margin-right: 0.5rem;
}

.story-image {
  max-width: 60px;
  max-height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f0f0f0;
  border-radius: 50%;
}

.story-image--selected {
  border: 2px solid #606099;
  background: white;
}

.story-image--selected > img {
  animation: animate-selected cubic-bezier(0.11, 0.96, 0.31, 0.9) 650ms;
  animation-direction: alternate;
}

@keyframes animate-selected {
  50% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0px);
  }
}

.story-image--greyed {
  filter: grayscale(1);
}

.story-text--selected {
  font-weight: 700;
  position: relative;
}

.story-text--selected::after {
  z-index: 1;
  outline: none;
  content: " ";
  position: absolute;
  bottom: -0.5rem;
  left: 50%;
  transform: translateX(-50%);
  border: 0.2rem solid;
  border-color: transparent transparent $primary-pink transparent;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  width: 1rem;
  height: 10px;
}

.story-text--selected::after:focus {
  outline: none;
}

@media only screen and (max-width: $bp-s) {
  .story-image {
    max-width: 42px;
    max-height: 42px;
  }
}
