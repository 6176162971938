.header-text {
  font-family: "Nunito Sans", sans-serif;
  line-height: 1.42857;
}

.header-text--xs {
  //   font-size: 24px;
  font-size: 1.25rem;
}

.header-text--s {
  //   font-size: 24px;
  font-size: 1.5rem;
}

.header-text--m {
  //   font-size: 36px;
  font-size: 2rem;
}
.header-text--l {
  font-size: 64px;
}

.header-text--bold {
  font-weight: bold;
}

.header-text--black {
  font-weight: 900;
}
